import svgPanZoom from "svg-pan-zoom";
import Hammer from "hammerjs";
export default class ReservationFloors {
  init() {
    this.list = $("#floor-list");
    this.top_image = $("#floor-top-image");
    this.active = 0;
    this.id = 0;
    this.users_home = "/users/home";
    this.users_res = "/users/reservations";
    this.wdays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
    let first = this.list.find("li:first");

    this.evalPlaces();
    this.setTop(first);
    this.attach();

    $("#reservation-date-click").click(function (evt) {
      evt.preventDefault();
      $("#reservation-date-value").click();
    });

    this.showSelectedFloor();
  }

  showSelectedFloor() {
    let matches = window.location.href.match(/floor_id=(\d+)/);

    if (!matches) {
      $("#reservation-index").show();
      return;
    }

    $("#reservation-search").hide();
    let floor_id = parseInt(matches[1]);
    this.setTop(this.list.find("li[data-id=" + floor_id + "]"));
    $("#selected_floor").val(floor_id);

    let list = window.location.href.match(/list=(\d)/);

    if (list && list[1] && parseInt(list[1]) == 1) {
      this.reservationFloorMapAction();
      return;
    }

    this.floorSelection(floor_id);
  }

  evalPlaces() {
    let places = $(".floor-stats");
    let place, id, total, occupied, element, perc;

    for (let i = 0, len = places.length; i < len; i++) {
      place = $(places[i]);
      id = place.attr("data-id");
      total = place.attr("data-total");
      occupied = place.attr("data-occupied");

      if (total == 0) {
        perc = 100;
      } else {
        perc = parseInt(100 / (total / occupied));
      }

      element = $("#floor-list").find("li[data-id=" + id + "]");
      element.attr("data-available", total - occupied);
      element.attr("data-perc", perc);
    }
  }

  changeURL(opts) {
    let base = "/users/reservations";
    let tokensv, key, val;

    let tokens = window.location.href
      .split(base)
      .slice(1)
      .map(function (param, idx) {
        param = param.replace("?", "");
        tokensv = param.split("=");
        key = tokens[0];
        val = tokens[1];

        if (opts[key]) {
          param = key + "=" + opts[key];
        }

        return param;
      });

    let params = "";

    window.location.href = base + params;
  }

  attach() {
    let ref = this;

    this.list.find("li").click(function (evt) {
      let element = $(evt.target);
      let index = parseInt(element.attr("data-index"));
      this.id = parseInt(element.attr("data-id"));

      ref.activate(element);
    });

    $("#floor-selection").click(function (evt) {
      evt.preventDefault();
      ref.floorSelection();
    });

    $("#space-selection").click(function (evt) {
      evt.preventDefault();
      ref.spaceSelection();
    });

    $("#list-selection").click(function (evt) {
      evt.preventDefault();
      ref.listSelection();
    });

    $("#slot-selection").click(function (evt) {
      evt.preventDefault();
      ref.showReservation();
    });

    $("#confirm-reservation").click(function (evt) {
      evt.preventDefault();
      ref.createReservation();
    });

    $(".reservation-floor-list-type").click(function (evt) {
      evt.preventDefault();
      let target = $(evt.target);
      let id = target.attr("data-id");

      $("#reservation-floor-list-types a").removeClass("active");
      $(target).addClass("active");
      $(".reservation-floor-space").hide();
      $("#reservation-floor-space-" + id).show();
    });

    $("#reservation-floor-map-action").click(function (evt) {
      evt.preventDefault();
      ref.reservationFloorMapAction();
    });

    $("#reservation-floor-menu-action").click(function (evt) {
      evt.preventDefault();
      ref.reservationFloorMenuAction();
    });

    $(".reservation-floor-space li").click(function (evt) {
      evt.preventDefault();
      let target = $(evt.target).closest("li");
      let floor = target.closest("div.reservation-floor");
      let fid = floor.attr("id").split("-")[2];
      let code = target.attr("data-code");
      let slot = ref.findSlot(fid, code);

      if (
        target.hasClass("reservation-list-status-full") ||
        target.hasClass("reservation-list-status-unavailable") ||
        target.hasClass("reservation-list-status-blocked") ||
        target.hasClass("reservation-list-status-group")
      ) {
        return;
      }

      if (slot) {
        let path = slot.find("path");
        let text = slot.find("tspan");

        if (path && text) {
          ref.activateSlot(path, text);
        }
      }

      $(".reservation-floor-space li").removeClass("active");
      $(".reservation-floor-space li div.capacity").hide();
      target.addClass("active");
      target.find(".capacity").show();
    });

    this.setTop(this.list.find("li:first"));
  }

  reservationFloorMenuAction() {
    let selected = parseInt($("#selected_floor").val());

    if (!isNaN(selected)) {
      let element = $("#reservation-map-" + selected);
      if (element.length > 0) {
        element.removeClass("reservation-map-hidden");
      }
    }

    $("#reservation-floor-menu-action").hide();
    $("#reservation-floor-map-action").show();
    $("#reservation-floor-list").hide();
    $("#reservation-maps").show();
  }

  reservationFloorMapAction() {
    let id = this.currentFloor().attr("data-id");

    $("#reservation-floor-map-action").hide();
    $("#reservation-floor-menu-action").show();
    $("#reservation-floor-list div.reservation-floor").hide();
    $("#reservation-maps").hide();
    $("#reservation-floor-" + id).show();
    $("#reservation-floor-list").show();
  }

  showGroups(floor_id, code) {
    $.ajax({
      url: "/api/spaces/groups",
      dataType: "json",
      data: { floor_id: floor_id, code: code },
    }).done(function (data) {
      $("#reservation-group-code").text(code);
      $("#reservation-group-groups").text(data.string);
      $("#reservation-group").modal();
    });
  }

  findSlot(floor, code) {
    return $("div#reservation-map-" + floor + " g#" + code);
  }

  activateList(floor, code) {
    let target = $(
      "#reservation-floor-" + floor + " li[data-code=" + code + "]"
    );

    if (!target) {
      return;
    }

    $(".reservation-floor-space li").removeClass("active");
    $(".reservation-floor-space li div.capacity").hide();
    target.addClass("active");
    target.find(".capacity").show();
  }

  activateSlot(path, text) {
    let cls = "reservation-selected-spot";
    let clst = "reservation-selected-spot-text";
    let exist = path.hasClass(cls);

    $("path").removeClass(cls);
    $("tspan").removeClass(clst);

    if (!exist) {
      path.addClass(cls);
      if (text) {
        text.addClass(clst);
      }
    } else {
      path.removeClass(cls);
      if (text) {
        text.removeClass(clst);
      }
    }
  }

  floor(idx) {
    return $("#floor-list li[data-index=" + idx + "]");
  }

  currentFloor() {
    return $("#floor-list li.floor-top");
  }

  activate(element) {
    let cls = "floor-top";
    let act = element.hasClass(cls);

    if (act) {
      this.showFloor(element);
    } else {
      this.hideFloors(element);
      this.setTop(element);
    }
  }

  showFloor(current) {
    let idx = parseInt(current.attr("data-index")) - 1;

    if (idx < 0) {
      return;
    }

    let tope = this.list.find("li[data-index=" + idx + "]");

    if (tope) {
      this.setTop(tope);
      tope.show();
    }
  }

  hideFloors(current) {
    let idx = parseInt(current.attr("data-index"));

    if (idx <= 0) {
      return;
    }

    let cur = null;
    idx--;

    for (; idx >= 0; idx--) {
      cur = this.list.find("li[data-index=" + idx + "]");

      if (cur) {
        cur.addClass("floor-exit");
      }
    }
  }

  setTop(element) {
    let cls = "floor-top";
    let name = $("#floor-name");
    let avail = $("#floor-available-count");
    let id = element.attr("data-id");
    let prog = $("#progress-bar");

    this.list.find("li").removeClass(cls);
    element.removeClass("floor-exit");
    element.addClass(cls);

    name.text(element.attr("data-name"));
    avail.text(element.attr("data-available"));
    prog.css("width", element.attr("data-perc") + "%");

    $("#where-floor").val(id);
  }

  floorSelection(floor_id) {
    let floor, id;

    if (floor_id) {
      floor = $("#floor-list li[data-id=" + floor_id + "]");
      id = floor_id;
      $("#selected_floor").val(floor_id);
    } else {
      floor = this.currentFloor();
      id = floor.attr("data-id");
      $("#selected_floor").val(this.currentFloor().attr("data-id"));
    }

    $("#floor-selection").hide();
    $("#space-selection").show();

    $(".reservation-map").hide();
    $("#reservation-index").hide();
    $("#reservation-search").hide();
    $("#reservation-floor-map-action").show();
    $("#reservation-map-" + id).show();
    $("#reservation-floor-back-arrow").attr("href", this.users_res);
  }

  formSubmit(id) {
    let tokens = $("#calendar").attr("data-selected").split("-");
    let date =
      tokens[0] +
      "-" +
      ("0" + (parseInt(tokens[1]) + 1)).slice(-2) +
      "-" +
      ("0" + tokens[2]).slice(-2);
    let form = $("#create-reservation-form");

    $("#code").val(id);
    $("#date").val(date);
    $("#floor_id").val(this.currentFloor().attr("data-id"));
    $("#space_code").val(id);

    this.slotStatus(id, date);
  }

  listSelection() {
    let spot = $("ul.reservation-floor-space li.active");

    if (spot.length < 1) {
      return false;
    }

    let id = spot.attr("data-code");
    this.formSubmit(id);
  }

  spaceSelection() {
    let spot = $(".reservation-selected-spot");

    if (spot.length < 1) {
      return false;
    }

    let id = spot.parent("g").attr("id");
    this.formSubmit(id);
  }

  createReservation() {
    $("#create-reservation-form").submit();
  }

  showReservation() {
    $("#reservation-index").hide();
    $("#reservation-maps").hide();
    $("#reservation-date").hide();
    $("#slot-list").hide();
    $("#slot-selection").hide();

    let tokens = new Date().toLocaleDateString().split("/");
    let today = tokens[2] + "-" + tokens[1] + "-" + tokens[0];
    let tokens_d = $("#date").val().split("-");
    let day_number = tokens_d[2];
    let date = new Date($("#date").val() + " 12:00:00");
    let day_label =
      $("#date").val() == today ? "Hoje" : this.wdays[date.getDay()];

    $(".confirm-location-label").text($("#location-name").text());
    $(".confirm-floor-label").text($("#floor-name").text());
    $(".confirm-code-label").text($("#code").val());
    $(".confirm-day-label").text(day_label);
    $(".confirm-day-number").text(day_number);

    $("#reservation-search").hide();
    $("#floor-selection").hide();
    $("#emptydiv").hide();
    $("#reservation-floor-map-action").hide();
    $("#reservation-floor-menu-action").hide();
    $("#more-days").hide();

    let slot = parseInt($("#slot").val());

    date = $("#date").val();
    let more = $("#more");
    more.val("");

    $("#reservation-data").show();
    $("#reservation-floor-close").show();
    $("#confirm-reservation").show();

    // $.ajax({
    //    url: '/api/spaces/more_days',
    //    dataType: 'json',
    //    data: { id: slot, date: date }
    // }).done(function(data) {
    //    $('#reservation-data').show();
    //    $('#reservation-floor-close').show();
    //    $('#confirm-reservation').show();

    //    if (data.length > 0) {
    //       let th_tr = $('#more-days-table thead tr');
    //       let tb_tr = $('#more-days-table tbody tr');
    //       let ref, td, hcls, dcls, cls, tdc, tdd;

    //       th_tr.empty();
    //       tb_tr.empty();

    //       $('#more-days').show();
    //       cls = 'more-day-sel';

    //       for (let i = 0, len = data.length; i < len; i++) {
    //          ref  = data[i];
    //          hcls = ref.day_abbr == 'Dom' || ref.day_abbr == 'Sab' || !ref.status ? 'more-day-un' : 'more-day-header';
    //          dcls = ref.day_abbr == 'Dom' || ref.day_abbr == 'Sab' || !ref.status ? 'more-day-un' : 'more-day-ok';

    //          td  = $('<td/>');
    //          td.addClass(hcls);
    //          td.append(ref.day_abbr);

    //          th_tr.append(td);

    //          td  = $('<td/>');
    //          td.append(ref.day);
    //          td.addClass(dcls);
    //          td.attr('data-date', ref.date);
    //          tb_tr.append(td);

    //          if (dcls != 'more-day-un') {
    //             td.click(function(evt) {
    //                tdc = $(evt.target);
    //                tdd = tdc.attr('data-date');

    //                if (tdc.hasClass(cls)) {
    //                   tdc.removeClass(cls);
    //                   more.val(more.val().replace(' ' + tdd + ' ', ''));
    //                } else {
    //                   tdc.addClass(cls);
    //                   more.val(more.val() + ' ' + tdd + ' ');
    //                }
    //             });
    //          }
    //       }
    //    }
    // });
  }

  showSlots(code, data) {
    if (data.length <= 1) {
      if (data.length == 0 || (data.length == 1 && !data[0].available)) {
        this.noMoreSlots(code);
        return false;
      }

      $("#slot").val(data[0].id);
      $("#slot-list").hide();
      $("#reservation-floor-list").hide();
      $("#space-selection").hide();
      $("#space-selection").hide();
      $("#starts_at").val(data[0].from);
      $("#finishes_at").val(data[0].to);

      this.showReservation();
      return;
    }

    $("#reservation-floor-list").hide();
    $("#reservation-maps").hide();
    $("#space-selection").hide();

    $("#slot-list").show();
    $("#reservation-floor-menu-action").hide();
    $("#reservation-floor-map-action").hide();
    $("#emptydiv").show();
    $("#floor-selection").hide();
    $("#slot-selection").show();

    let list = $("#slot-options");
    let slot, li;

    list.empty();

    for (let i = 0, len = data.length; i < len; i++) {
      slot = data[i];
      li = $("<li/>");
      li.attr("id", slot.id);
      li.attr("data-from", slot.from);
      li.attr("data-to", slot.to);
      li.append(slot.from + " - " + slot.to);
      li.addClass("user-list-option");

      if (slot.available) {
        li.click(function (evt) {
          evt.preventDefault();
          let cur = $(evt.target);

          list.find("li").removeClass("user-list-option-enabled");
          cur.addClass("user-list-option-enabled");
          $("#starts_at").val(cur[0].dataset.from);
          $("#finishes_at").val(cur[0].dataset.to);
        });
      } else {
        li.addClass("user-list-option-disabled");
      }

      list.append(li);
    }
  }

  noMoreSlots(code) {
    $("#reservation-code").text(code);
    $("#reservation-full").modal();
  }

  slotStatus(code, date) {
    let ref = this;
    let floor = this.currentFloor().attr("data-id");

    $.ajax({
      url: "/api/spaces/status",
      data: { code: code, date: date, floor: floor },
      dataType: "json",
    })
      .done(function (data) {
        ref.showSlots(code, data);
      })
      .fail(function () {
        ref.noMoreSlots(code);
      });
  }
}
