export default class Autocomplete {
   constructor(element, url, type, callback) {
      this.element  = element;
      this.url      = url;
      this.type     = type;
      this.div      = null;
      this.input    = null;
      this.list     = null;
      this.callback = callback;
      let ref       = this;

      this.createDiv();

      element.focus(function() {
         ref.showDiv();
      });
   }

   showDiv() {
      this.list.empty();
      this.input.val('');
      this.div.show();
      this.input.focus();
   }

   createDiv() {
      let did  = 'div#autocomplete-' + this.element.attr('id');
      this.div = $(did);

      let width  = $(this.div).width();
      let height = $(this.div).height();
      let pos    = $(this.element).offset();

      pos.position = 'absolute';
      pos.top = parseInt(pos.top) - height;
      pos.left = parseInt(pos.left) - width;
      this.div.css(pos);

      this.list = this.div.find('ul');

      if (this.div.length > 0) {
         this.attachInput();
         this.attachClose();
         return;
      }
   }

   attachClose() {
      let ref = this;

      this.div.find('.autocomplete-close').click(function(evt) {
         evt.preventDefault();
         ref.div.hide();
      });
   }

   attachInput() {
      let ref = this;
      this.input = this.div.find('input');

      this.input.keyup(function() {
         ref.eval();
      });
   }

   fill(data) {
      if (this.type == 'users') {
         this.fillUsers(data);
      }
      if (this.type == 'spaces') {
         this.fillSpaces(data);
      }
   }

   fillSpaces(data) {
      this.list.empty();
      let li;
      let ref = this;

      try {
         data.forEach(function(space) {
            li = $('<li/>');
            li.append('<span class="space-avatar rounded-circle" alt="' + space.name + '" title="' + space.name + '">' + space.code + '</span>');
            li.append('<strong>' + space.name + '</strong>');
            li.append('<em>' + space.location + '</em>');
            li.click(function(evt) { ref.select($(evt.target), space); });
            ref.list.append(li);
         });
      } catch(e) {
         console.error('Error filling spaces:');
         console.error(e);
      }
   }

   fillUsers(data) {
      this.list.empty();
      let li;
      let ref = this;

      data.forEach(function(user) {
         li = $('<li/>');

         if (user.avatar) {
            li.append('<img src="' + user.avatar + '" class="avatar rounded-circle"/>');
         } else {
            li.append('<span class="user-avatar-initials rounded-circle">' + user.initials + '</span>');
         }

         li.append('<strong>' + user.name + '</strong>');
         li.append('<em>' + user.email + '</em>');
         li.click(function(evt) { ref.select($(evt.target), user); });
         ref.list.append(li);
      });
   }

   select(li, data) {
      li = li.closest('li');
      li.siblings('li').removeClass('active');
      li.addClass('active');

      if (this.callback) {
         this.callback(data);
      }
   }

   eval() {
      let val = this.input.val();
      let ref = this;

      if (val.length < 1) {
         this.list.empty();
         return;
      }

      $.ajax({
         url: this.url,
         dataType: 'json',
         data: { term: val }
      }).done(function(data) {
         if (!data || data.length < 1) {
            return;
         }
         ref.fill(data);
      });
   }
}
