// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "bootstrap";
import "../stylesheets/application";

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("jquery-ui-dist/jquery-ui");

import "inputmask";

import ResourceSearch from "packs/resource_search";
window.ResourceSearch = ResourceSearch;

import Users from "packs/users";
window.Users = Users;

import Search from "packs/search";
window.Search = Search;

import ImageResize from "packs/image_resize";
window.ImageResize = ImageResize;

import UrlProc from "packs/url_proc";
window.UrlProc = UrlProc;

import Menu from "packs/menu";
window.Menu = Menu;

import ReservationFloors from "packs/reservation_floors";
window.ReservationFloors = ReservationFloors;

import Reservations from "packs/reservations";
window.Reservations = Reservations;

import MapDrag from "packs/map_drag";
window.MapDrag = MapDrag;

import Calendar from "packs/calendar";
window.Calendar = Calendar;

import UserReservations from "packs/user_reservations";
window.UserReservations = UserReservations;

import ConfirmationDialog from "packs/confirmation_dialog";
window.ConfirmationDialog = ConfirmationDialog;

import Pagination from "packs/pagination";
window.Pagination = Pagination;

import AutoComplete from "packs/autocomplete";
window.AutoComplete = AutoComplete;

import "@fortawesome/fontawesome-free/css/all";

import jsQR from "jsqr";
window.jsQR = jsQR;

require("chartkick/chart.js");

// window.ServiceWorkerRegistration = null;

// if (navigator.serviceWorker) {
//   navigator.serviceWorker
//     .register("/service-worker.js", { scope: "./" })
//     .then(function (reg) {
//       console.log("[Serviceworker]", "Service worker registered!");
//       console.log(reg);
//       window.ServiceWorkerRegistration = reg;
//     });
// }

Rails.start();
Turbolinks.start();
ActiveStorage.start();
