export default class ResourceSearch {
   attach(element, result, desc) {
      let ref = this;

      this.element = element;
      this.result  = result;
      this.desc    = desc;

      ref.showCurrent();

      element.keyup(function(evt) {
         let element = $(evt.target);
         let term    = element.val();

         if (term.length < 2) {
            result.empty();
            desc.show();
            ref.showCurrent();
            return;
         }

         result.empty();

         $.ajax({
            url: '/api/resources/search',
            dataType: 'json',
            data: { term: term }
         }).done(function(data) {
            desc.hide();
            result.empty();
            ref.createItems(data, result);
         });
      });
   }

   showCurrent() {
      let ref = this;

      $.ajax({
         url: '/api/favorites',
         dataType: 'json'
      }).done(function(data) {
         data = data.sort(function(first, second) {
            return first.type_name < second.type_name ? -1 : 1;
         });
         ref.desc.hide();
         ref.result.empty();
         ref.createItems(data, ref.result);
      });
   }

   createItems(data, result) {
      let rst;
      let ref = this;
      let cur = null;

      data.forEach(function(item, idx) {
         if (cur == null || item.type_name != cur) {
            cur = item.type_name;
            result.append('<li class="resource-search-type">' + cur + "</li>");
         }

         if (item.type == 'User') {
            result.append(ref.createUser(item));
         }

         if (item.type == 'Space') {
            result.append(ref.createSpace(item));
         }
      });
   }

   procFavorite(data, item, star) {
      if (data.favorited) {
         this.removeFavorite(item, star);
      } else {
         this.addFavorite(item, star);
      }
      return item;
   }

   addFavorite(item, star) {
      let ref = this;

      star.click(function(evt) {
         evt.preventDefault();

         $.ajax({
            url: '/api/favorites',
            dataType: 'json',
            method: 'post',
            data: { id: item.attr('data-id'), type: item.attr('data-type') }
         }).done(function(data) {
            star.unbind('click');
            star.removeClass('resource-search-favorite');
            star.addClass('resource-search-favorite-selected');
            ref.removeFavorite(item, star);
         });
      });
   }

   removeFavorite(item, star) {
      let ref = this;

      star.click(function(evt) {
         evt.preventDefault();

         $.ajax({
            url: '/api/favorite',
            dataType: 'json',
            method: 'delete',
            data: { id: item.attr('data-id'), type: item.attr('data-type') }
         }).done(function(data) {
            star.unbind('click');
            star.removeClass('resource-search-favorite-selected');
            star.addClass('resource-search-favorite');
            ref.addFavorite(item, star);
         });
      });
   }

   createSpace(data) {
      let item = $('<li/>');
      let code = $('<span/>');
      let name = $('<span/>');
      let loc  = $('<span/>');
      let star = $('<img/>');

      code.append(data.code);
      name.append(data.name);
      loc.append(data.location);

      if (data.favorited) {
         star.addClass('resource-search-favorite-selected');
      } else {
         star.addClass('resource-search-favorite');
      }

      item.addClass('resource-search-result-item-space');
      item.append(code);
      item.append(name);
      item.append(loc);
      item.append(star);
      item.attr('data-type', 'Space');
      item.attr('data-id', data.id);
      return this.procFavorite(data, item, star);
   }

   createUser(data) {
      let item  = $('<li/>');
      let img   = $('<img/>');
      let avail = $('<span/>');
      let name  = $('<span/>');
      let star  = $('<img/>');

      img.attr('src', data.avatar);
      img.addClass('resource-search-user-avatar rounded-circle');

      name.append(data.name);
      avail.addClass('user-status');

      if (data.available) {
         avail.addClass('status-green');
      } else {
         avail.addClass('status-red');
      }

      if (data.favorited) {
         star.addClass('resource-search-favorite-selected');
      } else {
         star.addClass('resource-search-favorite');
      }

      item.addClass('resource-search-result-item-user');
      item.append(img);
      item.append(avail);
      item.append(name);
      item.append(star);
      item.attr('data-type', 'User');
      item.attr('data-id', data.id);
      return this.procFavorite(data, item, star);
   }
}
