export default class UrlProc {
   static url(val, filter, unique) {
      let url = window.location.pathname;
      let reg = new RegExp("&?" + filter + "=-?[-/\\d]+", "g");

      let par  = window.location.search.replace(reg, "").replace(/^\?/, "");
      let send = [];

      if (par.length > 1 && !unique) {
         send.push(par);
      }

      if (val.length > 0) {
         send.push(filter + "=" + val);
      }

      if (send.length > 0) {
         url = url + "?" + send.join("&").replace(/^&/, "");
      }

      return url;
   }

   static filter(val, filter, unique) {
      window.location.href = this.url(val, filter, unique);
   }
}
