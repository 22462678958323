export default class Reservations {
   attachSuggested() {
      let ref = this;

      $('#reservation-floor-back-arrow').click(function(evt) {
         evt.preventDefault();
         $('#slot-list').hide();
      });

      $('#list-selection').click(function(evt) {
         evt.preventDefault();
         let slot = parseInt($('#slot').val());

         if (isNaN(slot) || slot < 1) {
            console.error('No slot selected');
            return;
         }

         let redir  = $('#reservation_ids').val();
         let date   = $('#reservation_date').val();

         ref.singleSuggestion(slot, date, redir);
      });

      $('ul#suggested_spaces li').click(function(evt) {
         evt.preventDefault();
         let target = $(evt.target).closest('li');
         let active = target.find('div').hasClass('active');

         if (active) {
            return;
         }

         let qty    = parseInt(target.attr('data-qty'));
         let id     = parseInt(target.attr('data-id'));
         let floor  = parseInt(target.attr('data-floor'));
         let code   = target.attr('data-code');
         let ids    = $('#reservation_ids').val();
         let redir  = $('#reservation_ids').val();
         let date   = $('#reservation_date').val();

         if (qty == 1) {
            ref.singleSuggestion(id, date, redir);
         } else {
            ref.multipleSelection(code, floor, date, redir);
         }
      });
   }

   singleSuggestion(id, date, redir) {
      $.ajax({
         url: '/users/reservations',
         method: 'post',
         data: { slot: id, date: date, redir: redir },
         dataType: 'json'
      }).done(function(data) {
         let ids = data.ids;
         window.location.href = '/users/reservations/' + ids + '/list';
      });
   }

   multipleSelection(code, floor, date, redir) {
      let ref = this;

      $.ajax({
         url: '/api/spaces/status',
         data: { code: code, date: date, floor: floor },
         dataType: 'json'
      }).done(function(data) {
         ref.showSlots(code, data);
      }).fail(function() {
         ref.noMoreSlots(code);
      });
   }

   showSlots(code, data) {
      console.log('show slots');
      console.log(code);
      console.log(data);

      $('#slot-list').show();

      let list = $('#slot-options');
      let slot, li;

      list.empty();

      for (let i = 0, len = data.length; i < len; i++) {
         slot = data[i];
         li   = $('<li/>');
         li.attr('id', slot.id);
         li.append(slot.from + ' - ' + slot.to);
         li.addClass('user-list-option');

         if (slot.available) {
            li.click(function(evt) {
               evt.preventDefault();
               let cur = $(evt.target);

               list.find('li').removeClass('user-list-option-enabled');
               cur.addClass('user-list-option-enabled');
               $('#slot').val(cur.attr('id'));
            });
         } else {
            li.addClass('user-list-option-disabled');
         }

         list.append(li);
      }
   }

   noMoreSlots(code) {
      console.error('no more slots');
      console.error(code);
      $('#reservation-code').text(code);
      $('#reservation-full').modal();
   }
}
