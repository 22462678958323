export default class Users {
   attach_password_confirmation() {
      let ref = this;

      $('form').submit(function(evt) {
         let errors = ref.passwordErrors();

         if (errors) {
            evt.preventDefault();
            alert(errors);
            return false;
         }
      });
   }

   passwordErrors() {
      let pwd1 = $('#password').val();

      if ($('#password_confirmation').length < 1) {
         return;
      }

      let pwd2 = $('#password_confirmation').val();

      if (pwd1 != pwd2) {
         return 'As senhas são diferentes!';
      }

      if (pwd1.length < 6 || pwd2.length < 6) {
         return 'As senhas são muito curtas!';
      }

      let current = $('#current_password');

      if (current.length > 0) {
         if (current.val().length < 6) {
            return "Por favor preencha a senha atual.";
         }
      }
   }

   attach_password_focus(text, icon, button) {
      let ref = this;

      text   = $('#' + text);
      icon   = $('#' + icon);
      button = $('#' + button);

      text.on('input', function(evt) {
         let errors = ref.passwordErrors();

         if (errors) {
            button.addClass('disabled');
            button.prop('disabled', true);
         } else {
            button.removeClass('disabled');
            button.prop('disabled', false);
         }
      });

      text.focusin(function(evt) {
         icon.removeClass('opaque-icon');
      });

      text.focusout(function(evt) {
         icon.addClass('opaque-icon');
      });

      icon.click(function(evt) {
         evt.preventDefault();

         let type = text.prop('type');
         let from, to, filter;

         if (type == 'password') {
            from   = 'icon-eye-outlined';
            to     = 'icon-eye';
            type   = 'text';
            filter = false;
         } else {
            from   = 'icon-eye';
            to     = 'icon-eye-outlined';
            type   = 'password';
            filter = true;
         }

         icon.removeClass(from);
         icon.addClass(to);
         text.prop('type', type);

         if (filter) {
            icon.addClass('opaque-icon');
         } else {
            icon.removeClass('opaque-icon');
         }
      });
   }

   attachImport(button, file, form) {
      let button_ref = $('#' + button);
      let file_ref   = $('#' + file);
      let form_ref   = $('#' + form);

      file_ref.change(function(evt) {
         $(document.body).append(form_ref);
         form_ref.submit();
      });

      button_ref.click(function(evt) {
         file_ref.click();
      });
   }

   evalPreferenceButtonOption(button, selected, choices, mchoices, required) {
      let disabled = 'disabled-preference-button';

      if (selected < choices) {
         button.addClass(disabled);
         button.attr('disabled', true);
         return;
      }

      button.removeClass(disabled);
      button.attr('disabled', false);
   }

   reflectChoices(element, group) {
      let selected = group.find('li.user-preference-option-enabled').map(function() {
         return $(this).attr('data-id');
      }).toArray().join(",");

      element = $('#' + element);
      element.val(selected);
   }

   attachUserTypeSearch(element) {
      $('#' + element).change(function(evt) {
         evt.preventDefault();
         UrlProc.filter($(evt.target).val(), 'user_type', true);
      });
   }

   attachUserActiveSearch(element) {
      $('#' + element).change(function(evt) {
         evt.preventDefault();
         UrlProc.filter($(evt.target).val(), 'user_actived', true);
      });
   }

   attachClientSearch(element) {
      $('#' + element).change(function(evt) {
         evt.preventDefault();
         UrlProc.filter($(evt.target).val(), 'client', true);
      });
   }

   attachAvatarChange(element) {
      $(element).click(function(evt) {
         evt.preventDefault();
         $('#avatar_input').click();
      });

      $('#avatar_input').change(function(evt) {
         evt.preventDefault();
         let resize = new ImageResize();
         resize.resize('avatar_input', 'img-avatar', 'avatar', 250, 250, function(content) {
            $.ajax({
               url: '/api/users/avatar',
               method: 'patch',
               data: { avatar: content }
            });
         });
      });
   }

   attachAvailableChange(element) {
      $(element).click(function(evt) {
         let checked = element.prop('checked');
         $('#reservation-status-text').text(checked ? 'Disponível' : 'Indisponível');

         $.ajax({
            url: '/api/users/available',
            method: 'patch',
            data: { available: (checked ? 1 : 0) }
         });
      });
   }

   attachPasswordChange(element) {
      $(element).click(function(evt) {
         evt.preventDefault();
         $.ajax({
            url: '/users/change_password',
            method: 'patch',
            dataType: 'json',
            data: {
               current_password: $('#current_password').val(),
               password: $('#password').val(),
               password_confirmation: $('#password_confirmation').val()
            }
         }).done(function(data) {
            $('#password-changed-text').text(data.message);
            $('#password-changed-modal').modal();
         }).fail(function(data) {
            if (data && data.responseJSON) {
               alert(data.responseJSON.message);
            }
         });
      });
   }

   startPreferences() {
      let ref = this;

      $('.user-preference').hide();
      $('.user-preference-start').show();

      $('.user-avatar-click').click(function(evt) {
         evt.preventDefault();
         $('#avatar_input').click();
      });

      $('#avatar_input').change(function(evt) {
         evt.preventDefault();
         let resize = new ImageResize();
         resize.resize('avatar_input', 'img-avatar', 'avatar', 250, 250);
      });

      $('.user-preference-action').click(function(evt) {
         evt.preventDefault();

         let element = $(evt.target);

         if (element.is("i")) {
            element = element.closest("button");
         }

         let div     = element.closest('div.user-preference');
         let target  = element.attr('data-target');

         if (target) {
            div.hide();
            let ndiv = $('#user-preference-' + target);
            ndiv.show();

            let group    = $(ndiv).find('ul');
            let selected = group.find('li.user-preference-option-enabled').length;
            let choices  = parseInt(group.attr('data-choices'));
            let button   = ndiv.find('button');

            if (selected >= choices) {
               button.removeClass('disabled-preference-button');
               button.attr('disabled', false);
            }
         }
      });

      $('.user-preference-birthday').change(function(evt) {
         let target   = $(evt.target);
         let value    = target.val();
         let element  = $('#' + target.attr('data-element'));
         let desc     = $('#' + target.attr('data-desc'));
         let token    = target.attr('data-token');
         let text     = target.find('option:selected').text();
         let tokens   = element.val() ? element.val().split('-') : ['', ''];
         let button   = target.closest('div.user-preference').find('button');
         let disabled = 'disabled-preference-button';

         if (token == 'day') {
            element.val(tokens[0] + '-' + value);
         } else {
            element.val(value + '-' + tokens[1]);
         }

         desc.text($('#day').val() + ' de ' + $('#month option:selected').text());

         if (!element.val().match(/^\d{2}-\d{2}$/)) {
            desc.hide();
            button.addClass(disabled);
            button.attr('disabled', true);
            return;
         }

         desc.show();
         button.removeClass(disabled);
         button.attr('disabled', false);
      });

      $('.user-preference-text').on('input', function(evt) {
         let element  = $(evt.target);
         let text     = element.val().trim();
         let button   = element.closest('div.user-preference').find('button');
         let disabled = 'disabled-preference-button';

         if (text.length < 1) {
            button.addClass(disabled);
            button.attr('disabled', true);
            return;
         }

         button.removeClass(disabled);
         button.attr('disabled', false);
         return;
      });

      $('.user-preference-option').click(function(evt) {
         let opt      = $(evt.target);
         let group    = $(opt).closest('ul');
         let selected = group.find('li.user-preference-option-enabled').length;
         let choices  = parseInt(group.attr('data-choices'));
         let mchoices = parseInt(group.attr('data-max-choices'));
         let required = group.attr('data-required') == 'true';
         let element  = group.attr('data-element');
         let enabled  = 'user-preference-option-enabled';
         let button   = opt.closest('div.user-preference').find('button');

         if (opt.hasClass(enabled)) {
            opt.removeClass(enabled);
            ref.evalPreferenceButtonOption(button, selected - 1, choices, mchoices, required);
            ref.reflectChoices(element, group);
            return;
         }

         if (selected >= mchoices) {
            if (mchoices == 1) {
               group.find('li').removeClass('user-preference-option-enabled');
            } else {
               return;
            }
         }

         opt.addClass('user-preference-option-enabled');
         ref.reflectChoices(element, group);
         ref.evalPreferenceButtonOption(button, selected + 1, choices, mchoices, required);
      });
   }
}
