export default class ConfirmationDialog {
  constructor(text, ok_label, cancel_label, ok_proc, cancel_proc, container) {
    this.background = $("<div/>");
    this.dialog = $("<div/>");
    this.buttons = $("<div/>");
    let p = $("<p/>");
    let ok = $("<button/>");
    let cancel = $("<button/>");

    this.background.attr("id", "confirmation-dialog-background");
    this.dialog.attr("id", "confirmation-dialog");

    cancel.append(cancel_label);
    cancel.attr("id", "confirmation-dialog-cancel");
    cancel.addClass("btn btn-outlined");
    cancel.click(cancel_proc);

    ok.append(ok_label);
    ok.attr("id", "confirmation-dialog-ok");
    ok.addClass("btn btn-blue");
    ok.click(ok_proc);

    p.append(text);
    this.dialog.append(p);

    this.buttons.addClass("confirmation-dialog-buttons");
    this.buttons.append(cancel);
    this.buttons.append(ok);
    this.dialog.append(this.buttons);

    this.background.append(this.dialog);

    let cont = container ? $("#" + container) : $("#main-container");
    cont.append(this.background);

    let height = 810;
    let dheight = this.dialog.height();
    let margin = parseInt((height - dheight) / 2);

    this.background.height(height);
    this.dialog.css({ top: margin + "px" });
    this.dialog.show();
  }

  hide() {
    this.background.remove();
  }
}
