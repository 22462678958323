export default class ImageResize {
   resize(input_id, img_id, target_id, max_width, max_height, callback) {
      let filesToUpload = document.getElementById(input_id).files;
      let file          = filesToUpload[0];
      let img           = document.getElementById(img_id);
      let reader        = new FileReader();

      reader.onload = function(e) {
         let img = new Image();
         img.src = this.result;

         setTimeout(function() {
            let canvas = document.createElement("canvas");

            let MAX_WIDTH  = max_width;
            let MAX_HEIGHT = max_height;
            let width      = img.width;
            let height     = img.height;

            if (width > height) {
               if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width   = MAX_WIDTH;
               }
            } else {
               if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
               }
            }

            canvas.width  = width;
            canvas.height = height;

            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            var dataurl = canvas.toDataURL("image/jpeg");

            document.getElementById(input_id).value  = '';
            document.getElementById(img_id).src      = dataurl;
            document.getElementById(target_id).value = dataurl;

            if (callback) {
               callback(dataurl);
            }
         }, 100);
      };

      reader.readAsDataURL(file);
   }
}
