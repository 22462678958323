export default class UserReservations {
  attachAdmin() {
    $(".admin-cancel-reservation-action").click(function (evt) {
      evt.preventDefault();
      let target = $(evt.target).closest("a");
      let id = target.attr("data-id");

      if (!confirm("Tem certeza?")) {
        return false;
      }

      $.ajax({
        url: "/api/reservations/" + id + "/cancel",
        dataType: "json",
        method: "DELETE",
      })
        .done(function (data) {
          let container = target.closest(".admin-user-reservation");
          if (container) {
            container.remove();
          }
        })
        .fail(function (data) {
          alert("Erro cancelando a reserva");
        });
      return false;
    });
  }

  attach() {
    let ref = this;

    this.attachCheckin();
    this.attachCheckout();
    this.attachClock();

    $(".cancel-reservation-action").click(function (evt) {
      evt.preventDefault();

      let dialog = new ConfirmationDialog(
        "Tem certeza que deseja cancelar essa reserva?",
        "Sim, cancelar",
        "Voltar",

        function () {
          dialog.hide();

          let a = $(evt.target).closest("a");
          $.ajax({
            url: a.attr("href"),
            method: "DELETE",
          });

          return true;
        },

        function () {
          evt.preventDefault();
          dialog.hide();
          return false;
        }
      );

      return false;
    });
  }

  attachClock() {
    $(".modal-clock").click(function (evt) {
      evt.preventDefault();

      let clock = $(evt.target).closest("button");
      let limit = clock.attr("data-time");
      let code = clock.attr("data-code");

      $("#reservation-limit").text(limit);
      $("#reservation-code").text(code);
    });
  }

  attachCheckin() {
    $(".confirm-reservation-checkin").click(function (evt) {
      evt.preventDefault();

      let dialog = new ConfirmationDialog(
        "Tem certeza que deseja fazer checkin?",
        "Sim",
        "Voltar",

        function () {
          dialog.hide();

          let a = $(evt.target).closest("a");
          window.location.href = a.attr("href");
          return true;
        },

        function () {
          evt.preventDefault();
          dialog.hide();
          return false;
        }
      );

      return false;
    });
  }

  attachCheckout() {
    $(".confirm-reservation-checkout").click(function (evt) {
      evt.preventDefault();

      let dialog = new ConfirmationDialog(
        "Tem certeza que deseja fazer checkout?",
        "Sim",
        "Voltar",

        function () {
          dialog.hide();

          let a = $(evt.target).closest("a");
          window.location.href = a.attr("href");
          return true;
        },

        function () {
          evt.preventDefault();
          dialog.hide();
          return false;
        }
      );

      return false;
    });
  }

  backToOffice(evt) {
    evt.preventDefault();

    $.ajax({
      url: "/users/reservations/back_to_office",
    });
  }

  /**
   * Eval home office status.
   */
  evalHomeOfficeStatus(evt) {
    let check = $("#home-office-status");

    if (!check.is(":checked")) {
      this.backToOffice(evt);
      return;
    }

    if (this.reservationsToday() > 0) {
      this.askHomeOffice(evt);
    } else {
      this.setHomeOffice();
    }
  }

  /**
   * Return how many today reservations exists
   * @return reservation count
   */
  reservationsToday() {
    return $(".reservation-select-today").length;
  }

  /**
   * Set user to home office
   */
  setHomeOffice() {
    $("#home-office-action").click();

    $.ajax({
      url: "/users/reservations/home_office",
      method: "DELETE",
    });
  }

  /**
   * Ask confirmation for home office
   */
  askHomeOffice(evt) {
    let ref = this;

    let dialog = new ConfirmationDialog(
      "Ativando o trabalho remoto, suas reservas marcadas para hoje serão canceladas. Ok?",
      "Sim, continuar",
      "Voltar",

      function (evt) {
        evt.preventDefault();
        dialog.hide();
        ref.setHomeOffice();
      },

      function (evt) {
        evt.preventDefault();
        $("#home-office-status").prop("checked", false);
        dialog.hide();
      }
    );
  }
}
