export default class Menu {
   static attachToggle() {
      $('.toggle-menu').click(function(evt) {
         evt.preventDefault();
         let link  = $(evt.target).closest('a');
         let menu  = link.siblings('.menu-content');
         let arrow = link.find(".menu-arrow");
         let cls   = 'rotate';

         if (!menu) {
            return;
         }

         if (arrow.hasClass(cls)) {
            arrow.removeClass(cls);
         } else {
            arrow.addClass(cls);
         }

         menu.slideToggle();
      });
   }

   static attachActions() {
      $('#context-menu').click(function() {
         $('#context-menu').hide();
      });

      $('.context-menu-actions').click(function(evt) {
         let posX = $(this).offset().left;
         let posY = $(this).offset().top;
         let menu = $('#context-menu');
         let menW = menu.width();
         let menY = menu.height();
         let id   = $(evt.target).attr('data-id');
         let href, pos, dig;

         menu.find('a').each(function(idx) {
            href = $(this).attr('href');
            dig  = href.match(/\d+/g).pop();
            pos  = href.lastIndexOf(dig);

            if (pos < 0) {
               return;
            }
            href = href.substring(0, pos) + id + href.substring(pos + dig.length);
            $(this).attr('href', href);
         });

         menu.css({ top: posY - menY, left: posX - (menW * 1), position: 'absolute', 'z-index': 9999 });
         menu.show();
      });
   }

   static lockScreen(mode) {
      try {
         screen.orientation.lock(mode).then(function() {
            alert('Locked!');
         }).catch(function(e) {
            alert("Lock error on promise: " + e);
         });
      } catch(e) {
         alert('Lock error: ' + e);
      }
   }
}
