export default class Calendar {
  attach(id) {
    try {
      let today = new Date();
      let ref = this;

      this.render(today.getFullYear(), today.getMonth());
      this.showActive();

      $("#reservation-calendar").click(function (evt) {
        evt.preventDefault();
        $("#calendar").show();
      });

      $("#calendar_prev").click(function (evt) {
        evt.preventDefault();
        ref.prev();
      });

      $("#calendar_next").click(function (evt) {
        evt.preventDefault();
        ref.next();
      });

      $("#calendar-cancel").click(function (evt) {
        evt.preventDefault();
        $("#calendar").hide();
      });

      $("#calendar-select").click(function (evt) {
        evt.preventDefault();
        let div = $("#calendar");
        div.hide();

        let floor_id = parseInt($("#selected_floor").val());
        let tokens = div.attr("data-selected").split("-");
        let date =
          tokens[0] +
          "-" +
          ("0" + (parseInt(tokens[1]) + 1)).slice(-2) +
          "-" +
          ("0" + tokens[2]).slice(-2);
        let url = UrlProc.url(date, "date");

        if (!isNaN(floor_id)) {
          let floor_url = "floor_id=" + floor_id;
          let matches = url.match(/floor_id=\d+/);
          let visible_list = $("#reservation-floor-menu-action").is(":visible")
            ? 1
            : 0;

          if (matches) {
            url = url.replace(/floor_id=\d+/, floor_url);
          } else {
            url = url + "?" + floor_url;
          }

          matches = url.match(/list=\d+/);

          if (matches) {
            url = url.replace(/list=\d+/, "list=" + visible_list);
          } else {
            url = url + "&list=" + visible_list;
          }
        }

        // window.location.href = url;
      });
    } catch (e) {
      console.error("Calendar error: " + e);
    }
  }

  getMonthName(month) {
    return [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ][month];
  }

  setMonthName(month) {
    $("#calendar_month").text(this.getMonthName(month));
  }

  attachActivation() {
    let ref = this;

    $("#calendar td").click(function (evt) {
      evt.preventDefault();
      ref.activate($(evt.target));
    });
  }

  showActive() {
    let selected = $("#calendar").attr("data-selected");
    let element = $("#calendar-el-" + selected);

    if (element.length > 0) {
      this.activate(element);
    }
  }

  toInt(year, month, day) {
    return parseInt(year + ("0" + month).slice(-2) + ("0" + day).slice(-2));
  }

  isBeforeToday(element) {
    let div = $("#calendar");
    let year = div.attr("data-year");
    let month = div.attr("data-month");
    let day = element.attr("data-day");
    let today = new Date();
    let todayi = this.toInt(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    let elemi = this.toInt(year, month, day);
    return elemi < todayi;
  }

  isAfterMaxDayReservation(element) {
    let div = $("#calendar");
    let year = div.attr("data-year");
    let month = div.attr("data-month");
    let day = element.attr("data-day");

    const selectedDate = new Date(year, month, day);
    let openingForReservation = document.getElementById('calendar').dataset.openingForReservation;
    if (openingForReservation < 1) return false;

    let maxDate = new Date();
    const newDate = parseInt(maxDate.getDate()) + parseInt(openingForReservation) - 1
    maxDate.setDate(newDate);

    return selectedDate > maxDate;
  }

  activate(element) {
    if (this.isBeforeToday(element) || this.isAfterMaxDayReservation(element)) {
      return;
    }

    $("#calendar").attr("data-day", element.text());
    $("#calendar td").removeClass("selected");

    element.addClass("selected");
    this.showCurrentLabel();
    let id = element.attr("id").replace("calendar-el-", "");
    $("#calendar").attr("data-selected", id);
  }

  prev() {
    try {
      let div = $("#calendar");
      let year = div.attr("data-year");
      let month = div.attr("data-month");
      let first = new Date(year, month, 1).getTime();
      let prevd = new Date(first - 1000 * 60 * 60 * 24);
      this.render(prevd.getFullYear(), prevd.getMonth());
    } catch (e) {
      console.error("Error on previous calendar: " + e);
    }
  }

  next() {
    try {
      let div = $("#calendar");
      let year = div.attr("data-year");
      let month = div.attr("data-month");
      let last = new Date(
        year,
        month,
        this.lastDay(year, month),
        12,
        0,
        0
      ).getTime();
      let nextd = new Date(last + 1000 * 60 * 60 * 24);
      this.render(nextd.getFullYear(), nextd.getMonth());
    } catch (e) {
      console.error("Error on next calendar: " + e);
    }
  }

  showCurrentLabel() {
    try {
      let cur = $("#calendar_cur");
      let today = new Date();
      let year = parseInt($("#calendar").attr("data-year"));
      let month = parseInt($("#calendar").attr("data-month"));
      let day = parseInt($("#calendar").attr("data-day"));
      let label = "Hoje";

      if (
        today.getFullYear() != year ||
        today.getMonth() != month ||
        today.getDate() != day
      ) {
        label = day + " de " + this.getMonthName(month);
      }

      cur.text(label);
    } catch (e) {
      console.error("Error on current label: " + e);
    }
  }

  render(year, month) {
    let div = $("#calendar");
    let tbody = div.find("tbody");
    let today = new Date();
    let first = new Date(year, month, 1);
    let day,
      wday,
      td,
      tr,
      last = this.lastDay(year, month);
    let cur,
      pre = true;

    div.attr("data-year", year);
    div.attr("data-month", month);
    this.setMonthName(month);
    tbody.empty();

    for (day = 1; day <= last; day++) {
      cur = new Date(year, month, day);
      wday = cur.getDay();

      if (!tr) {
        tr = $("<tr/>");
      }

      if (pre) {
        for (let i = 0; i < wday; i++) {
          tr.append($("<td/>"));
        }
        pre = false;
      }

      td = $(
        '<td id="calendar-el-' +
          year +
          "-" +
          month +
          "-" +
          day +
          '" data-day="' +
          day +
          '">' +
          day +
          "</td>"
      );

      if (
        cur.getFullYear() == today.getFullYear() &&
        cur.getMonth() == today.getMonth() &&
        cur.getDate() == today.getDate()
      ) {
        td.prop("class", "today");
      }

      if (this.isBeforeToday(td) || this.isAfterMaxDayReservation(td)) {
        td.addClass("disabled");
      }

      tr.append(td);

      if (wday >= 6) {
        tbody.append(tr);
        tr = null;
      }
    }

    tbody.append(tr);
    this.attachActivation();
    this.showActive();
  }

  lastDay(year, month) {
    try {
      let day, date;

      for (day = 31; day >= 1; day--) {
        date = new Date(year, month, day);
        if (date.getDate() == day) {
          return day;
        }
      }
    } catch (e) {
      console.error("Error on last day for " + year + "/" + month);
    }
    return 31;
  }
}
