export default class MapDrag {
  attach() {
    let element,
      elements = $(".reservation-map");

    for (let i = 0, len = elements.length; i < len; i++) {
      try {
        element = $(elements[i]);
        // element.draggable();
      } catch (e) {
        console.error("Error dragging: " + e);
      }
    }
  }
}
